import './App.css';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import React from 'react';

 
const getItemSize = (index) =>  {return 60};
if(!window.listContactDataStore) window.listContactDataStore = {};
let dataArray = [];
const Row = ({ index, style }) => {

  if(dataArray.length===0) return (<div></div>);

  let item = dataArray[index];
  
  return (
        <li className={item.renderType !== "group" ? 'chat-entry' : 'chat-entry group'} style={style}>
            <div className={window.selectedUser === item.id ? 'contact-list-convo selected' : 'contact-list-convo'} data-channel-id={item.renderType !== "group" ? item.channelId : item.groupChannelId} data-last-message={item.lastMessageSentDate} data-name={item.fullName} data-user-id={item.id} data-rep-id={item.repId}>
                <a href="#" className="list-group-item list-group-item-action py-2">
                    <div className="row">
                        <div className='col-10'>
                            <div className="name">{item.fullName}</div>
                        </div>
                    </div>
                  <div className="row contact-info">
                        <div className="col-10 group"><small>Group Chat</small></div>
                        <div className="col-5 text-left individual"><small className="rep-id">Rep #<span>{item.repId}</span></small></div>
                        <div className="col-7 pl-0 individual"><small data-role="payout-level" className="payout-level" data-payout-level-type={item.payoutLevelType}>{item.payoutLevelName}</small></div>
                    </div>
                  <div className='message-count' data-count={item.unreadMessagesCount}>{item.unreadMessagesCount}</div>
                </a>
            </div>
        </li>
  )
};





class ContactList extends React.Component{

  constructor(){
    super();
    let _this = this;
    window.forceContactRender = function(){
      //_this.forceUpdate();
      dataArray = Object.values(window.listContactDataStore);
      if(!window.contactSortType || window.contactSortType === "contact-list-convo"){
        dataArray.sort(function(a, b){
          return new Date(b.lastMessageSentDate) - new Date(a.lastMessageSentDate);
      });
      }else if(window.contactSortType === "name"){
        dataArray.sort(function(a, b){
          if(a.fullName < b.fullName) { return -1; }
          if(a.fullName > b.fullName) { return 1; }
          return 0;
        });
      }else if(window.contactSortType === "payout-level"){
        dataArray.sort(function(a, b){
          if(!b.payoutLevelType) b.payoutLevelType = 0;
          if(!a.payoutLevelType) a.payoutLevelType = 0;
          return b.payoutLevelType - a.payoutLevelType;
        });
      }

      if(window.filterText){
        dataArray = dataArray.filter(function(data){
           return data.fullName.toLocaleLowerCase().indexOf(window.filterText.toLocaleLowerCase()) !== -1;
           
        })
      }

      _this.setState({count: dataArray.length, classGen: "list" + Math.random()});
    }
  }
  componentDidMount() {
    this.setState({count: dataArray.length, classGen: "list" + Math.random()});
  }
  
  render(){
    return (
      <AutoSizer>
      {({ height, width }) => (
        <List
          className={this.state.classGen}
          height={!isNaN(height) ? height : 0}
          itemCount={this.state.count === 0 ? 1 : this.state.count }
          itemSize={getItemSize}
          width={width}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
    )};
  }


/*
function App() {

  this.forceRender();
 
*/
export default ContactList;
